.confirm_page{
    display: flex;
    width: 100%;
    min-height: 100vh;
    position: relative;
}
.confirm_page_blue_side{
    display: flex;
    width: 100%;
    height: 50vh;
    background-color: #0079ff;
}
.confirm_page_content{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    max-width: 725px;
    height: 578px;
    display: flex;
    flex-direction: column;
}
.confirm_logo_container{
    margin-bottom: 40px;
    display: flex;
    width: 100%;
    justify-content: center;
    height: fit-content;
}
.confirm_logo_container img{
    height: fit-content;
}
.confirm_des_container{
    display: flex;
    width: 100%;
    background-color: white;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.confirm_icon{
    margin-top: 75px;
    display: flex;
    width: 100%;
    justify-content: center;
}
.confirm_des_title{
    display: flex;
    width: 100%;
    justify-content: center;
    font-family: Montserrat-SemiBold;
    font-size: 25px;
    color: #000000;
    margin-top: 35px;
}
.confirm_des_des{
    display: flex;
    width: 100%;
    max-width: 600px;
    text-align: center;
    margin-top: 20px;
    font-family: Montserrat-Light;
    font-size: 16px;
    color: #848484;
    margin-bottom: 74px;
}
.confirm_button_container{
    display: flex;
    width: 100%;
    justify-content: center;
}
.confirm_button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 170px;
    height: 55px;
    margin-top: 70px;
    font-size: 16px;
    font-family: Montserrat-SemiBold;
    color: #0078ff;
    border: 5px solid #0078ff;
    cursor: pointer;
    transition: 0.5s;
    text-transform: uppercase;
}
.confirm_button:hover{
    color: white;
    background-color: #0078ff;
}
@media only screen and (max-width: 767px) {
    .confirm_icon{
        margin-top: 40px;
    }
    .confirm_page_content{
        position: static;
        margin: unset;
        max-width: unset;
        margin-top: -93px;
    }
    .confirm_page{
        flex-direction: column;
    }
    .confirm_page_blue_side{
        height: 150px;
    }
    .confirm_des_title{
        font-size: 20px;
    }
    .confirm_des_container{
        background-color: transparent;
    }
    .confirm_button{
        margin-top: unset;
    }
}