.shop_page_slider{
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.shop_slider_item{
    display: flex;
    height: 400px;
    margin-right: 10px;
    font-family: Montserrat-Medium;
    justify-content: center;
    align-items: center;
    color: white;
    text-transform: uppercase;
    text-align: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.shop_slider_button_container{
    position: absolute;
    right: 50px;
    background-color: transparent;
}

.shop_lef_slider_button_container{
    position: absolute;
    background-color: transparent;
    top: 165px;
    left: 100px;
}
.shop_page_slider .slick-prev::before{
    background-image: url("../../../assets/left-arrow.svg"); 
    background-repeat: no-repeat;
    background-position: center;
    border: 5px solid #4da1ff;
    width: 45px;
    height: 45px;
    background-color: #2345ff;
    outline: none !important;
    font-size: 40px;
    opacity: unset;
    color: unset!important;
}
.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before{
    opacity: 0!important;
}


.shop_page_slider .slick-next::before{
    border: 5px solid #4da1ff;
    width: 45px;
    height: 45px;
    background-color: #2345ff;
    outline: none !important;
    font-size: 40px;
    opacity: unset;
    color: unset!important;
    background-image: url("../../../assets/right-arrow.svg");
    background-repeat: no-repeat;
    background-position: center;
}
.slick-track{
    margin-left: 0!important;
}

.slick-next{
    z-index: 555;
    right: 100px!important;
    top: 190px!important;
}
.slick-prev{
    z-index: 555;
    top: 190px!important;
    left: 60px!important;
}
.slick-prev, .slick-next{
    width: 50px!important;
    height: 50px!important;
}
.link-brd{
    text-decoration: none!important;
}
.collectiion-sale{
 height: 100%;
 width: 100%;
}
@media only screen and (max-width:1440px){
    .shop_page_slider .slick-next::before,
    .shop_page_slider .slick-prev::before{
        font-size: 25px;
    }
    .slick-next{
        top: 195px!important;
        right: 25px!important;
    }
    .slick-prev{
        top: 195px!important;
        left: 5px!important;
    }
    .shop_slider_item{
        background-size: cover;

    }
}
@media only screen and (max-width: 991px) {
.shop_slider_button_container , .shop_lef_slider_button_container{
       display: none;
    }
    .shop_slider_item{
        height: 175px;
    }
}