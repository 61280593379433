.footer{
    background-color: black;
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 80px;
    padding-left: 17px;
    padding-right: 17px;
    flex-direction: column;
    margin-top: 83px;
    align-items: center;
    margin-top:auto;
}
.footer_content{
    display: flex;
    width: 100%;
    max-width: 1480px;
    background-color: white;
    padding: 60px 50px;
}
.footer_des_container{
    display: flex;
    flex-direction: column;
    padding-right: 60px;
    width: 45%;
}
.footer_des_title{
    font-size: 35px;
    color: #1a1a1a;
    font-family: Montserrat-BOLD;
    word-break: break-word;

}
.footer_des{
    margin-top: 70px;
}
.footer_des p{
    font-size: 18px;
    font-family: Montserrat-Light;
    color: black;
}
.footer_form_fields{
   display: flex;
    flex: 1;
    margin-right: 40px;
    min-width: calc(50% - 40px);
}
.footer_form_fields form{
    width: 100%;
}
.footer_form_container{
    display: flex;
    width: 55%;
    flex-wrap: wrap;
}
.footer_content .MuiInput-underline:before{
    border-bottom: 2px solid black ;
}
.footer_content .MuiFormControl-root{
    width: 100%;
}
.footer_content .MuiFormLabel-root.Mui-focused{
    color: black ;
    font-family: Montserrat-Regular;
    font-size: 18px;
}
.footer_content .MuiInput-underline:after{
    border-bottom: 2px solid black;
}
.footer_content .MuiInputLabel-animated{
    color: black ;
    font-family: Montserrat-Regular;
    font-size: 18px;
}
.footer_content .MuiInputBase-input{
    padding: 10px 0;
}

.footer_content .form_notifications {
    width: 100%;
    margin-right: 40px;
    text-align: center;
}

.footer_form_textarea{
    width: 100%;
    margin-top: 30px;
}
.footer_form_textarea textarea{
    border: 2px solid black;
    width: calc(100% - 40px);
    padding: 8px;
}
.footer_button_container{
    display: flex;
    width: calc(100% - 40px);
    justify-content: flex-end;
    margin-top: 30px;
}
.page_button{
    display: inline-block;
    float: right;
    font-size: 16px;
    text-transform: uppercase;
    padding: 10px 25px;
    height: max-content;
    font-family: Montserrat-SemiBold;
    letter-spacing: 1px;
    background: -moz-linear-gradient(top, #39b6e3 0%, #189dd9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#39b6e3', endColorstr='#189dd9',GradientType=0 );
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.2s ease-in-out;
    border: 5px solid #0078ff;
    color: #0078ff;
    cursor: pointer;
}
.page_button:hover{
    color: rgb(202, 201, 201);
    background: #0078ff;
}
.footer_bottom_links{
    display: flex;
}
.footer_bottom_links .ftr-links{
     color: rgba(255, 255, 255, 0.39);
    padding-right: 20px;
    padding-bottom: 20px;
    text-decoration: none!important;
}
.footer_bottom_links a:hover{
    color:white;
    cursor: pointer;
}
.footer_bottom_side.footer_side_pdng{
    padding: 30px 0 20px 0;
}
.footer_side_pdng{
    color: rgba(255, 255, 255, 0.39);
}
.footer_bottom_side{
    padding: 30px 0 0 0;
    max-width: 1480px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.39);
    ;
}
.footer_socials_container{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.footer_socials_container a{
    text-decoration: none !important;
    color: white !important;
    margin: 7px 0;
    font-family: Montserrat-Light;
    font-size: 14px;
}
.footer_address_container{
    padding-right: 36px;
    display: flex;
}
.footer_address_container a,
.footer_address_container p{
    color: white;
    font-family: Montserrat-Light;
    font-size: 14px;
    text-align: right;
    margin-bottom: 5px;
    padding-right: 20px
}
.footer_address_container a:hover{
    text-decoration: unset;
    color: white;
    cursor: pointer;
}

@media only screen and (max-width: 1440px) {
    .footer_bottom_links,
    .footer_address_container{
        flex-wrap: wrap;
    }
    .footer_bottom_links{
        justify-content: flex-end;
    }   
    .footer_bottom_links ,
    .footer_address_container p{
        white-space: nowrap;
        
    }
}
@media only screen and (max-width: 991px) {
    .footer_des{
        margin-top: 35px;
    }
    .footer_des_title{
        font-size: 25px;
    }
    .footer_content{
        flex-direction: column;
        padding: 50px 20px;
    }
    .footer_des_container{
        width: 100%;
        padding-right: 0;
    }
    .footer_form_container{
        width: 100%;
        flex-direction: column;
    }
    .footer_form_fields{
        min-width: unset;
        width: 100%;
        margin: 0;
        margin-bottom: 15px;
    }
    .footer_form_textarea textarea{
        width: 100%;
    }
    .footer_button_container{
        width: 100%;
    }
    .footer_socials_container{
        display: none;
    }
    .footer_address_container{
        align-content: flex-start;
    }
    .footer_address_container p{
        text-align: start;
        height: 30px!important;
    }
}

@media only screen and (max-width: 600px) {
    .footer_bottom_side{
        flex-direction: column;
    }
    .footer_bottom_links{
        justify-content: flex-start;
    }
 
}