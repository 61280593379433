.cart_header{
    display: flex;
    width: 100%;
    background-color: white;
    height: 77px;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 9px 1px #85868757;
}
.cart_header_title{
    color: #051a2e;
    font-family: Montserrat-SemiBold;
    font-size: 18px;
    padding-left: 40px;
}
.braind-link{
    text-decoration: none!important;
}
.cart_label{
    font-size: 15px;
}
.cart_header_cart_container{
    display: flex;
    align-items: center;
    padding-right: 60px;
}
.black_heart:hover{
    content: url('../../../assets/favorite_with_item_hover.svg');
}
.black_heart{
    cursor: pointer;
    height: 18px;
    width: 25px;
    margin-right: 10px;
}
.cart_header_container{
    display: flex;
    align-items: center;
    cursor: pointer;

}
.cart_header_container:hover{
    color: #0078ff;
}
.cart_header_container:hover img{
    content: url('../../../assets/shopping_bag_hover.svg');
}
.cart_header_container img{
    height: 16px;
    width: 16px;
    margin-right: 10px;
}
@media only screen and (max-width: 991px) {
    .cart_header_title{
        padding-left: 20px;
    }
    .cart_header_cart_container{
        padding-right: 20px;
    }
}