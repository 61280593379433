.out_of_stock{
    display: flex;
    width: 100%;
    max-width: 400px;
    background-color: white;
    flex-direction: column;
    padding: 30px;
    margin-top: 30px;
}
.out_of_stock .MuiInput-underline:before{
    border-bottom: 2px solid black ;
}
.out_of_stock .MuiFormControl-root{
    width: 100%;
}
.out_of_stock .MuiFormLabel-root.Mui-focused{
    color: black;
    font-family: Montserrat-Regular;
    font-size: 18px;
}
.out_of_stock .MuiInput-underline:after{
    border-bottom: 2px solid black ;
}
.out_of_stock .MuiInputLabel-animated{
    color: black ;
    font-family: Montserrat-Regular;
    font-size: 18px;
}
.out_of_stock .MuiInputBase-input{
    padding-bottom: 20px;
}
.out_of_stock_title{
    font-family: Montserrat-SemiBold;
    font-size: 16px;
    color: #051a2e;
}
.out_of_stock_text{
    font-family: Montserrat-Light;
    color: #848484;
    font-size: 16px;
    margin-top: 10px;
}
.stock_checkbox_container{
    display: flex;
    padding-top: 25px;
}
.stock_checkbox_container .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.stock_checkbox_container .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.stock_checkbox_container .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: white;
    border: 2px solid black;
}
.stock_checkbox_container .container:hover input ~ .checkmark {
    background-color: white;
}
.stock_checkbox_container .container input:checked ~ .checkmark {
    background-color: white;
}
.stock_checkbox_container .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.stock_checkbox_container .container input:checked ~ .checkmark:after {
    display: block;
}
.stock_checkbox_container .container .checkmark:after {
    left: 4px;
    top: 1px;
    width: 5px;
    height: 8px;
    border: solid #0078ff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.payment_checkbox_container{
    display: flex;
    flex-direction: column;
}
.stock_checkbox_label{
    font-family: Montserrat-Regular;
    font-size: 15px;
    color: #5a5a5a;
}
.stock_button_container{
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
}
.stock_button{
    display: flex;
    width: 190px;
    height: 45px;
    justify-content: center;
    align-items: center;
    font-family: Montserrat-SemiBold;
    font-size: 16px;
    color: #0078ff;
    border: 5px solid #0078ff;
    text-transform: uppercase;
    transition: 0.5s;
    cursor: pointer;
}
.stock_button:hover{
    color: white;
    background-color: #0078ff;
}
@media only screen and (max-width: 991px){
    .out_of_stock{
        max-width: unset;
    }
    .stock_button{
        width: 100%;
    }
}