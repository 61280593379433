.delivery-header{
    display: flex;
    width: 100%;
    height: 200px;
    background-color: #0079ff;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.delviery-title{
    display: flex;
    justify-content: center;
    font-size:40px;
    background-color: white;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0;
    font-family: Montserrat-Medium;
    padding: 8px 20px 8px 30px;
    font-weight: 500;
}
.payment-flex{
    display: flex;
    justify-content: center;
    padding: 50px 0;
    max-width: 1440px;
}
.payment-text{
    padding: 0 20px 83px 20px;
}

.payment-text-title{
    max-width: 350px;
    width: 100%;
}
.payment-text-contnet {
    max-width: 851px;
    width: 100%;
    font-family: Montserrat-Medium;
    word-break: break-word;
}
.payment-text-title h2{
    font-size: 35px;
    font-family: Montserrat-Medium;
    word-break: break-word;

}

@media only screen and (max-width:1440px){
    .payment-text-contnet{
        padding: 0 40px;
    }
    .payment-text-title{
        max-width: 300px;
        width: 100%;
        padding: 0 20px;
    }
    .payment-text{
        padding: 0 20px 83px 20px;
    }
}
@media only screen and (max-width:991px){
    .payment-flex{
        flex-direction: column;
        padding: 30px 0;
    }
    .payment-text-contnet{
        padding: 0;
    }
    .payment-text-title{
        padding: 0;
        margin-bottom: 20px;
    }
    .payment-text{
        padding-bottom: 33px;
}
.delviery-title{
    margin: 100px auto 40px;
}
.delivery-header{
    height: unset;
    }
}