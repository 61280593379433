.product_slider{
    display: flex;
    width: 100%;
    max-width: 840px;
    padding-right: 40px;
    flex-direction: column;
}
.product_slider_image{
    display: flex;
    width: 100%;
}
.product_slider_image img{
    width: 100%;
    height: 350px;
    object-fit: contain;

}
.product_slider_indicators_container{
    display: flex;
    width: 100%;
    cursor: pointer;
}
.product_slider_indicator{
    display: flex;
    width: 120px;
    height: 90px;
    margin-right: 15px;
    margin-top: 15px;
}
.product_slider_indicator:last-child{
    margin-right: 0;
}
.product_slider_indicator img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    
}
.product_slider_indicator img.active{
    border: solid 2px #0078ff;
}
    
.product_slider_indicator.active_indicator{
    border: 2px solid #0078ff;
}

@media only screen and (min-width:1600px){
.product_slider_image img{
height:  600px;
    }
}
@media only screen and (max-width:1440px){
    .product_slider_indicator{
        margin-right: 5px;
    }

}
@media only screen and (min-width:1300px) and (max-width:1480px) {
    .details_page_content{
        padding: 50px 30px 0 30px;
    }
}
@media only screen and (max-width: 991px) {
    .product_slider_image img{
        height: 550px;
    }
    .product_slider{
        padding-right: 0;
    }
    .product_slider_indicator{
        width: 60px;
        height: 50px;
        margin-right: 10px;
        margin-top: 10px;
    }
}
@media only screen and (max-width:425px){
    .product_slider_image img{
        height: 246px;
        object-fit: contain;
    }
}