.shop_grid_header{
    display: flex;
    width: 100%;
    justify-content: center;
}
.shop_grid_header_content{
    display: flex;
    width: 100%;
    max-width: 1920px;
    height: fit-content;
    padding-top: 30px;
    padding-left: 17px;
    padding-right: 17px;
    align-items: center;
    justify-content: space-between;

}
.shop_header_select_placeholder{
    display: flex;
    width: 100%;
    align-items: center;
}
.select_icon{
    background-image: url("../../../assets/sort_by.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 20px;
    height: 20px;
    display: flex;
    margin-right: 7px;
}
.isopen-li .select_icon {
    display: none!important;
 }
.isopen-li .shop_header_select_placeholder .shop_header_select_label::after{
     display: none!important;
 }

.shop_header_select_container .MuiFormControl-root{
    width: 100%;
}
.shop_header_select_label{
    font-family: Montserrat-Regular;
    font-size: 15px;
    color: #848484;
    position: relative;
}
.shop_header_select_label:after{
    content: ' ';
    display: flex;
    width: 12px;
    height: 12px;
    position: absolute;
    right: -21px;
    top: 4px;
    background-image: url("../../../assets/arrow_down.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.shop_header_select_container .MuiInput-underline:before{
    display: none;
}
.shop_header_select_container .MuiSelect-icon{
    display: none;
}
.MuiPopover-paper{
    min-width: 200px !important;
}
.shop_header_select_container .MuiInput-underline:after{
    border-bottom: none !important;
}
.shop_header_select_container .MuiSelect-select:focus{
}
.MuiListItem-button:hover{
    background-color: #c0eeff !important;
    color: #051a2e !important;
    font-weight: 700;
}
.MuiMenuItem-root{
    font-family: Montserrat-Regular;
    color: #848484 !important;
}
.shop_header_select_container .MuiSelect-select.MuiSelect-select{
    color: #848484 !important;
}
.shop_header_nav_container{
    display: flex;
    width: fit-content;
}
.shop_header_nav{
    list-style-type: none;
    list-style-position: inside;
    display: flex;
    margin: 0;
}
.shop_header_nav li{
    margin-right: 7px;
}
.shop_header_nav li a{
    display: flex;
    font-family: Montserrat-Regular;
    color: #051a2e;
    font-size: 18px;
    padding: 0px 7px;
    text-decoration: none !important;
    position: relative;
}
.shop_header_nav li a span{
    position: relative;
    z-index: 10;
}
.shop_header_nav li a:after{
    content: ' ';
    position: absolute;
    width: 0;
    height: 13px;
    background-color: #c0eeff;
    bottom: 0;
    left: 0;
    transition: 0.5s;
}
.shop_header_nav li:hover > a:after{
    width: 100%;
}
.active_shop_link:after{
    width: 100% !important;
}
.shop_header_cart_container{
    font-family: Montserrat-Medium;
    color: #231f20;
    font-size: 15px;
}
.shop_header_icons_container{
    display: flex;
}
.black_heart_container{
    cursor: pointer;
    margin-right: 10px;
}
.black_heart_container:hover img{
    content: url("../../../assets/favorite_with_item_hover.svg");
}
     .black_heart_container img{
        width: 25px;
        height: 18px;
    } 

.shop_cart_container{
    display: flex;
    align-items: center;
    padding-top: 2px;
    cursor: pointer;
    
}
.shop_cart_container:hover{
    color: #0078ff;

}
.shop_cart_container:hover img{
    content: url("../../../assets/shopping_bag_hover.svg");

}
 .shop_cart_container img{
    width: 16px;
    height: 16px;
} 
.cart_icon{
    height: 17px;
    margin-right: 5px;
}
@media only screen and (max-width: 1100px) {
    .shop_header_select_container{
        order: 1;
    }
    .shop_header_icons_container{
        order: 2;
    }
    .shop_grid_header_content{
        flex-wrap: wrap;
        padding: 20px 10px;
    }
    .shop_header_nav_container{
        order: 3;
        width: 100%;
        margin-top: 30px;
        overflow-x: auto;
        overflow-y: hidden;
    }
    .shop_header_nav li a span{
        white-space: nowrap;
    }
}