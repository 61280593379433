*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    background-color: #f5f5f6 !important;
}
@font-face {
    font-family: Montserrat-ExtraLight;
    src: url("../../fonts/MONTSERRAT-EXTRALIGHT.TTF");
}
@font-face {
    font-family: Montserrat-Regular;
    src: url("../../fonts/MONTSERRAT-REGULAR.TTF");
}
@font-face {
    font-family: Montserrat-Light;
    src: url("../../fonts/MONTSERRAT-LIGHT.TTF");
}
@font-face {
    font-family: Montserrat-MadiumItalic;
    src: url("../../fonts/MONTSERRAT-MEDIUMITALIC.TTF");
}
@font-face {
    font-family: Montserrat-Medium;
    src: url("../../fonts/MONTSERRAT-MEDIUM.TTF");
}
@font-face {
    font-family: Montserrat-SemiBold;
    src: url("../../fonts/MONTSERRAT-SEMIBOLD.TTF");
}
@font-face {
    font-family: Montserrat-BOLD;
    src: url("../../fonts/MONTSERRAT-BOLD.TTF");
}
@font-face {
    font-family: MontserratArm-ExtraLight;
    src: url("../../fonts/MONTSERRATARM-EXTRALIGHT.OTF");
}
@font-face {
    font-family: MontserratArm-Regular;
    src: url("../../fonts/MONTSERRATARM-REGULAR.OTF");
}
@font-face {
    font-family: MontserratArm-Light;
    src: url("../../fonts/MONTSERRATARM-LIGHT.OTF");
}
@font-face {
    font-family: MontserratArm-Medium;
    src: url("../../fonts/MONTSERRATARM-MEDIUM.OTF");
}
@font-face {
    font-family: MontserratArm-SemiBold;
    src: url("../../fonts/MONTSERRATARM-SEMIBOLD.OTF");
}
@font-face {
    font-family: MontserratArm-Bold;
    src: url("../../fonts/MONTSERRATARM-BOLD.OTF");
}