.shop_grid_container{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.shop_grid_content{
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    padding-top: 63px;
    padding: 17px 0;
    max-width: 1780px;
    width: 100%;

}
.shop_grid_section_link {
    display: flex;
    width: 100%;
}
.shop_grid_section_link:hover {
    text-decoration: none;
}

.shop_grid_section_title{
    display: flex;
    width: 100%;
    font-family: Montserrat-Medium;
    color: white;
    font-size: 18px;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    height: 200px;
    margin: 23px 17px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.shop_grid_item{
    display: flex;
    width: calc(100% / 5);
    padding: 17px;

}
.product_link{
    height: 100%;
    text-decoration: none!important;
}
.shop_grid_item_content{
   background-color: white;
    width: 100%;
    transition: 0.5s;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}
.shop_item_flex{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    padding: 10px 0;
}
.shop_grid_item_content:hover{
    box-shadow: 0px 0px 10px 6px rgba(133, 134, 135, 0.25);
}
.cart-stl{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    font-family: Montserrat-Regular;
    background: blue;
    z-index: 100;
    color: white;
    text-transform: uppercase;
    font-size: 13px;
    padding: 0 10px;
    margin: 2px 0;
    white-space: nowrap;
}

.cart_bestseller{
    background-color:#FFAE00;
}
.cart_out{
    background-color: rgb(128, 128, 128);
}
.cart_sale{
    background-color: #FF2E24;
    width: 50px;
    height: max-content;
    white-space: normal;
}
.labels-container{
    align-items: flex-end;
    position: absolute;
    z-index: 10;
    right: 17px;
    top: 30px;
    display: flex;
    flex-direction: column-reverse;
}


.shop_grid_item_image{
    width: 100%;
    height: 290px;
    border-bottom: 1px solid #f5f5f6;
    padding:10px;
    position: relative;
}
.shop_grid_item_image img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    visibility: hidden;
    background-color: white;
}
.shop_grid_item_image:hover img{
    animation: thumbnailSlider  linear infinite 0s;
}
.line{
    position: absolute;
    width: 100%;
    border-top: solid 1px;
    top: 10px;
}
.fav-icn{
    position: absolute;
    cursor: pointer;
    z-index: 100;
    top: 37px;
    left: 20;
    left: 37px;
    cursor: pointer;
}
@keyframes thumbnailSlider {
    0% { visibility: visible; z-index: 100; }
    100% { visibility: hidden; z-index: 0; }
}
.shop_grid_item_image img:first-child{
    visibility: visible;
    z-index: 1;
}
.shop_item_title{
    font-family: Montserrat-SemiBold;
    font-size: 16px;
    color: #051a2e;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
    text-align: center;
    padding:0 30px;
overflow-wrap: anywhere;
word-break: break-word;
}
.shop_item_price{
    color: #0078ff;
    font-family: Montserrat-SemiBold;
    font-size: 16px;
    display: flex;
    justify-content: center;
    margin: 25px 0px;
}
.price{
color: red;
}
.shop_item_price span{
    display: flex;
    align-items: center;
    white-space: nowrap;
}
.shop_item_price .item-discount{
    color: grey;
    padding-right: 20px;
}

.item-discount  .line-rel{
    position: relative;
}
@media only screen and (min-width:1800px){
    .shop_grid_item{
        width: calc(100% / 5);
    }
}
@media only screen and (max-width: 1350px) {
   
    .shop_grid_item{
        width: calc(100% / 4);
    }
}
@media only screen and (min-width:1440px){
    .shop_grid_section_title{
        margin: 23px 17px;
    }
}
@media only screen and (max-width: 991px) {
    .shop_item_price .item-discount{
        padding-right: 0;
    }
   .fav-icn{
    top: 30px;
    left: 30px;
   }
   .labels-container{
       top: 24px;
       right: 10px;
   }
    .shop_grid_section_title{
        margin: 15px 10px;
    }
    .shop_item_title{
       overflow-wrap: anywhere;
        margin-top: 15px;
        padding: 0 30px;
    }

    .shop_item_title{
        font-size: 14px;
    }
    .shop_item_price{
        overflow-wrap: anywhere;
        margin: 15px 0 10px 0;
        font-size: 14px;
        padding: 0px 25px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-self: center;
    }
    .shop_grid_item{
        width: calc(100% / 2);
        padding: 10px;
    }
    
}
@media only screen and (max-width: 425px) {
    .hy .cart-stl , .ru .cart-stl {
        max-width: min-content;
        height: auto;
        white-space: normal;
        padding: 0 5px;
    }
    .shop_item_title{
        padding: 0 15px;
    }
    .fav-icn{
        left: 22px;
        top: 22px;
        z-index: 5555;
    }
    .labels-container{
        top: 18px;
        right: 10px;
    }
    .cart-stl{
        font-size: 10px;
        height: auto;
   }
    .shop_grid_section_title{
        height: 100px;
        font-size: 16px;
        width: calc(100% - 20px);
    }   
    .shop_grid_item_image{
        
        height: 130px;
    }
}

@media only screen and (max-width: 320px) {
    .labels-container{
        top: 18px;
        right: 18px;

        
    }
.shop_grid_item_content{
    width: 131px;
    
    
}
}