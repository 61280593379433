.similar_products_section{
    margin-top: 90px;
}
.similar_products_title{
    display: flex;
    width: 100%;
    font-family: Montserrat-Medium;
    font-size: 30px;
    padding-left: 40px;
}
.similar_products_grid_container{
    display: flex;
    width: 100%;
    justify-content: flex-start;
}
.similar_products_grid_content{
    display: flex;
    width: 100%;
    max-width: 1740px;
    flex-wrap: wrap;
    padding-left: 23px;
}
.similar_product_item{
    display: flex;
    width: calc(100% / 5);
    padding: 17px;
}
.similar_product_item_content{
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: white;
}
.similar_product_image{
    width: 100%;
    height: 300px;
    border-bottom: 1px solid #f5f5f6;
}
.similar_product_image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.similar_products{
    color: #0078ff;
}

.similar_products .discount-price{
    color: grey;
}
.similar_products .product-discount .line-rel .line{
    top: unset;
}
.price{
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
}
.similar_products{
    display: flex;
    align-items: center;
    justify-content: center;
}
.similar_products span{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}
.similar_products span:not(:first-child) img{
width: 15px;
}
.similar_product_item_content .fav_cnt  {
    position: relative;
}
.fav-icn-details{
    position: absolute;
    cursor: pointer;
    z-index: 5;
    cursor: pointer;
    left: 20px;
    top: 20px;
}
.similar_product_title{
    overflow-wrap: anywhere;
    margin-top: 20px;
    text-align: center;
    font-family: Montserrat-SemiBold;
    font-size: 14px;
    color: #051a2e;
    word-break:break-word;
    padding:0 40px;
}
.similar_cnt{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}
.similiar_link{
    text-decoration: none!important;
    height: 100%;
}
.line-rel{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;
}
.product-discuoun{
    color: grey;
    padding-right: 5px;
}
.similar_products{
    margin-top: 40px;
    text-align: center;
    font-family: Montserrat-SemiBold;
    font-size: 14px;
    color: #0078ff;
    padding-bottom: 30px;
}
@media only screen and (max-width: 1440px) {
    .similar_product_item{
        width: calc(100% / 4);
    }
}
@media only screen and (max-width: 1200px) {
    .similar_product_item{
        width: calc(100% / 3);
    }
}
@media only screen and (max-width: 991px) {
    .similar_products span{
        font-size: 14px;
    }
    .similar_products_section {
        margin-top: 45px;
    }
    .similar_products_title {
        padding-left: 20px;
    }
    .similar_products_grid_content{
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 40px;
    }
    .similar_product_item{
        width: calc(100% / 2);
        padding: 10px;
    }
}
@media only screen and (max-width:425px){
    .fav-icn-details{
        top: 10px;
        left: 10px;
    }
    .similar_product_title{
        padding:0 20px;
        margin-top: 15px;
    }
    .similar_product_item_content{
        padding-top:0;
    }
    .similar_products{
        margin-top: 30px;
        padding-bottom: 15px;
    }
    .similar_product_image{
        width: unset;
        max-height: 131px;
    }
    .similar_product_image img{
        padding: 5px 0;
    }
}