.navigation_bar{
    display: flex;
    width: 80px;
    height: 100vh;
    background-color: white;
    position: fixed;
    left: 0;
    top: 0;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 200;
}
.nav_lang_item.active-bold{
    color: black!important;
}
.nav_logo{
    margin-top: 20px;
    width: 40px;
    height: 48px;
    background-image: url("../../assets/logo.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.nav_logo img{
    width: 40px;
    height: 48px;
    object-fit: contain;
}
.hamburger_menu{
    cursor: pointer;
    display: flex;
    width: 100%;
    justify-content: center;
}
.nav_socials{
    width: 100%;
    height: 90px;
    margin-bottom: 37px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
}
.nav-social{
    color: black;
    margin-top: 10px;
}
.nav_content{
    display: flex;
    width: 627px;
    background-color: white;
    height: 100vh;
    position: fixed;
    left: -100%;
    transition: all 1s ease-in-out;
    padding-left: 80px;
    flex-direction: column;
    justify-content: space-between;
    z-index: 100;
}
.nav_content.nav_active{
    top: 0;
    left: 0;
}
.nav_header{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    padding-right: 54px;
    height: fit-content;
}

.nav_header_logo img{
    width: 120px;
}
.nav_lang_container{
    cursor: pointer;
    display: flex;
    text-transform: uppercase;
}
.nav_lang_item{
    font-size: 18px;
    color: #808080;
    font-family: Montserrat-SemiBold;
    margin: 0 10px;
    text-decoration: unset;
    display: flex;
    align-items: center;
}
.nav_pages_list_container{
    width: 100%;
    display: flex;
    background-color: white;
}
.nav_phone_number{
    font-size: 18px;
    color: #000000;
    font-family: Montserrat-SemiBold;
    text-decoration: none;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 45px;
    padding-right: 64px;
    list-style: none;
    z-index: 44444;
}
.nav_phone_number li{
    position: relative;
}
.nav_phone_number li::after{
    content: '';
    position: absolute;
    width: 0;
    height: 13px;
    background-color: #c0eeff;
    bottom: 0;
    left: 0;
    transition: 0.5s;
}
.nav_phone_number li:hover::after{
    width: 100%;
    z-index: 0;
}
.nav_phone_number a{
    color: black!important;
    text-decoration: none!important;
    position: relative;
    z-index: 1000;
}
.nav_menu{
    list-style-type: none;
    list-style-position: inside;
    margin-left: 40px;

}
.nav_menu li{
    margin-bottom: 13px;
    transition: 0.7s;
}
.nav_menu li:not(.nav_service_pages){
    margin-bottom: 30px;
}
.nav_menu li a{
    display: flex;
    font-size: 18px;
    text-transform: uppercase;
    color: #000000;
    font-family: Montserrat-SemiBold;
    text-decoration: none;
}
.nav_menu .nav_service_pages a {
    color: #747474;
    font-size: 14px;
    text-transform: unset;
}
.nav_mobile_logo{
    display: none;
}
.nav_menu.nav_nav_menu_active li{
    transform: translate(0);
    transition-delay: 1s;
}
.nav_menu.nav_nav_menu_unactive li{
    transform: translate(-200%);
    transition-delay: 0s!important;
}

@media only screen and (max-width: 991px) {
    .nav_content{
        width: 100%;
        height: calc(100vh - 60px);
        margin-top: 60px;
        left: 100%;
        padding-left: 0;
        transition: all 0.7s ease-in-out;
    }
    .nav_content.nav_active{
        left: 0;
    }
    .navigation_bar{
        width: 100%;
        height: 70px;
        flex-direction: row;
        justify-content: flex-start;
        border-bottom:1px solid #F5F5F6;
    }
    .navigation_bar a {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .nav_socials{
        display: none;
    }
    .nav_logo{
        margin-top: 0;
        margin-left: 17px;
    }
    .nav_mobile_logo{
        display: flex;
        width: 80px;
        height: 40px;
        background-image: url("../../assets/logo2.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-left: 15px;
    }
    .hamburger_menu{
        width: fit-content;
        position: absolute;
        top: calc(50% - 7px);
        right: 10px;
    }
    .nav_header_logo{
        display: none;
    }
    .nav_header{
        padding-right: 0;
        justify-content: center;
    }
    .nav_menu{
        margin-left: 0;
        width: 100%;
    }
    .nav_menu li a{
        justify-content: center;
    }
    .nav_phone_number{
        padding-right: 0;
        justify-content: center;
    }
    
    
    .nav_menu.nav_nav_menu_active li{
        transform: translate(0);
        transition-delay: 1s;
    }
    .nav_menu.nav_nav_menu_unactive li{
        transform: translate(+200%);
    }
}
