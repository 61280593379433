.page_container{
    display: flex;
    width: 100%;
    overflow: hidden;
}
.page_content{
    display: flex;
    margin-left: 80px;
    width: calc(100vw - 80px);
    flex-direction: column;
    min-height: 100vh;
}
@media only screen and (max-width: 991px) {
    .page_content{
        margin-left: 0;
        width: 100%;
        margin-top: 60px;
    }
}