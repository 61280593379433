.details_page_content{
    display: flex;
    width: 100%;
    padding: 80px 40px 0 40px;
}

@media only screen and (max-width: 991px) {
    .details_page_content{
        flex-direction: column;
        padding: 25px 20px 0px 20px;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1300px){
    .details_page_content{
        padding-right: 20px;
        padding-left: 20px;
    }
}