.product_description{
    display: flex;
    width: 100%;
    flex-direction: column;
}
.product_description_title{
    font-size: 25px;
    font-family: Montserrat-SemiBold;
    color: #051a2e;
    margin-top: 20px;
    word-break: break-word;
}
.product-discount .line{
    top: 18px;
}
.product_details_price span{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-size: 25px;
}
.product-discount {
    margin-right: 5px;
}
.product_details_price img {
    width: 18px;
}
.product_des_text{
    font-size: 16px;
    font-family: Montserrat-Light;
    color: #848484;
    margin-top: 15px;
    max-width: 600px;
}
.package_container{
    display: flex;
    flex-direction: column;
    margin-top: 19px;
}
.product_packages_title{
    font-family: Montserrat-Light;
    font-size: 16px;
    color: #051a2e;
}
.package_items{
    display: flex;
    margin-top: 25px;
}
.package_item_icon{
    margin-right: 15px;
}
.package_item_des{
    display: flex;
    flex-direction: column;
}
.package_item_title{
    font-size: 16px;
    font-family: Montserrat-SemiBold;
    color: #051a2e;
}
.package_item_text{
    font-family: Montserrat-Light;
    color: #5a5a5a;
    font-size: 16px;
}
.product_details_price{
    margin-top: 40px;
    font-size: 25px;
    color: #0078ff;
    font-family: Montserrat-SemiBold;
    display: flex;
    align-items: center;
}
.product_details_price img{
    width: 24px;
}
.product_sizes_container{
    display: flex;
    margin-top: 40px;
}
.product_sizes_container .MuiFormControl-root{
    width: 200px;
    height: 55px;
    border: 5px solid #dadada;
}
.product_sizes_container .MuiInputBase-root{
    height: 100%;
}
.product_sizes_container .MuiInput-underline:before{
    border: none;
}
.product_sizes_container .MuiSelect-select.MuiSelect-select{
    padding-right: 24px;
    padding-left: 17px;
    line-height: 25px;
    color: #848484;
}
.product_sizes_container .MuiInput-underline:hover:not(.Mui-disabled):before{
    border-bottom: none !important;
}
.product_sizes_container .MuiInput-underline:after{
    border-bottom: none !important;
}
.product_sizes_container .MuiSelect-icon path{
    display: none;
}
.product_sizes_container .MuiSelect-icon{
    background-image: url("../../../../../assets/bottomvector.png");
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: center;
}
.product_count_container{
    display: flex;
    align-items: center;
    border: 5px solid #dadada;
    width: 160px;
    height: 55px;
    padding: 0px 20px;
    margin-right: 20px;
}
.product_count_button{
    color: #0078ff;
    cursor: pointer;
}
.product_count_input{
    width: 100%;
    border: none;
    outline: none !important;
    background: transparent;
    text-align: center;
}
.products_sizes_select{
    margin-right: 20px;
}
.product_details_button{
    font-family: Montserrat-SemiBold;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 55px;
    color: #0078ff;
    border: 5px solid #0078ff;
    transition: 0.5s;
    cursor: pointer;
    margin-right: 20px;
}
.product-btn-cnt{
    display: flex;
}
.product_details_fav-button{
  width: 55px;
  height: 55px;
}
.product_details_button:hover img{
    content: url(../../../../../assets/heart-white-selected.svg);
}
.product_details_button:hover{
    color: white;
    background-color: #0078ff;
}
.free_delivery_section{
    display: flex;
    align-items: center;
    margin-top: 40px;
}
.free_delivery_icon{
    height: fit-content;
    margin-right: 15px;
}
.free_delivery_text{
    display: flex;
    align-items: center;
    margin: 0;
}
@media only screen and (min-width: 992px) and (max-width:1300px) {
    .product_sizes_container{
        display: flex;
        flex-wrap: wrap;
    }   
    .product_sizes_container div{
        margin-bottom: 10px;
    }
    .product_count_container div {
        margin: 0;
    }
}
@media only screen and (max-width: 991px) {
    .product_details_price{
        margin-top: 30px;
    }
    .product_details_price img {
        width: 22px;
    }
   .product-discount .line{
        top: 18px;
    }
    .product_count_container{
        padding: 0 10px;
    }
    .product-btn-cnt{ 
    width: 100%;

        justify-content: space-between;
    }
  
.product_details_fav-button{
    margin-right: 0;
    min-width: 55px;
}
    .product_details_price{
        font-size: 16px;
    }
    .package_container{
        margin-top: 12px;
    }
    .product_description_title{
     font-size: 20px;   
    }
    .product_details_button {
        margin-top: 20px;
    }
.product_details_button:first-child{
    width: 100%;
}
    .product_sizes_container{
        flex-wrap: wrap;
    }
    .product_sizes_container .MuiFormControl-root{
        width: 100%;
    }
    .products_sizes_select{
        width: calc(60% - 20px);
    }
    .product_count_container{
        width: unset;
        flex: 1;
        margin-right: 0;
    }
    .free_delivery_section{
        margin-top: 30px;
    }
.product_sizes_container{
    margin-top: 15px;
    } 
}
@media only screen and (max-width:320px){
    .product_details_button:first-child{
        width: 200px;
    }
    .products_sizes_select{
        width: 160px;
    }
}












